import React from 'react';
import { Helmet } from 'react-helmet';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import TitleCard from '../../components/TitleCard.js';
import CollapsibleModal from '../../components/CollapsibleModal.js';

const containerStyle = {
    width: '100%',
    height: '40vh',
  };
  
const center = {
    lat: 59.3398663,
    lng: 18.0526239
  };


function FindUsView() {

    const mapKey = process.env.REACT_APP_MAP_KEY;
    
    return (
    <div>
        <Helmet>
                <title>Hitta till oss</title>
                <meta name="description" content="Behöver du komma i kontakt med Primetime? Här finns alla kontaktuppgifter!"/>
        </Helmet>


    <div className="default-page-container emoji-image-1">

        <div className="faq-text-container">
            <TitleCard 
            title="Kontakta oss! 👋"
            body=""/>

            <CollapsibleModal 
            emoji="✍️"
            title="Kontaktuppgifter"
            body={<div>
                    <p className="expanded-modal-body">
                        <b>Business inquiries:</b> 
                        <br/>
                        samarbete@primetimequiz.com
                        <br/>
                        <br/>
                        <b>Samarbeten och annonsering:</b> 
                        <br/>
                        samarbete@primetimequiz.com
                        <br/>
                        <br/>
                        <b>Presskontakt:</b> 
                        <br/>
                        martin.palm@primetimequiz.com
                        <br/>
                   </p>
                </div>
            }/>

            <CollapsibleModal 
            emoji="🏠"
            title="Postadress"
            body={<div>
                    <p className="expanded-modal-body">
                    Primetime<br/>
                    c/o MAG Interactive AB<br/>
                    Drottninggatan 95<br/>
                    113 60 Stockholm
                   </p>
                </div>
            }/>

            <CollapsibleModal 
            emoji="💸"
            title="Fakturering"
            body={<div>
                    <p className="expanded-modal-body">
                    Sventertainment AB<br></br>
                    Orgnummer: 559155-8464<br></br>
                    Drottninggatan 95 <br></br>
                    11360 Stockholm
                   </p>
                </div>
            }/>
            
            <CollapsibleModal 
            emoji="🗺"
            title="Hitta till oss"

            body={
                <div>
                    <div className="map">
                        <LoadScript
                            googleMapsApiKey={mapKey}>
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={15}>
                                <Marker
                                    position={center}
                                    // label={address}
                                    >
                                </Marker>
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            }/>
        </div>
    </div>
    </div>
)
}

export default FindUsView;
