import React from 'react';


const TitleCard = ({title, body}) => {
    return (
        <div className="titlecard-container">
            <h1 className="titlecard-title">
                {title}
            </h1>
            <p className="titlecard-body">
                {body}
            </p>
        </div>
    )
}

export default TitleCard;