import React, { useState } from 'react';

export default function HostCard({ hostcard }) {
    const [flip, setFlip] = useState(false);
    
    return (
        <div className={`card ${flip ? 'flip' : ''}`}>
            <div className="front" onClick={ () => setFlip(!flip)}>
                <img src={hostcard.img} className="card-pp" alt={`${hostcard.name}`} ></img>
                <div className={hostcard.title.length < 25 ? "h2-host" : "h2-host-long"}>{hostcard.title}</div>
                <div className="h1-host">{hostcard.name}</div>
            </div>

            <div className="back">
                <div onClick={ () => setFlip(!flip)}>
                    {hostcard.desc}
                </div>
                <div className="card-mail" onClick={()=>console.log("hej")}>
                    {hostcard.mail}
                </div> 
            </div>
            
        </div>
    )
}


