import React from 'react'
import ReactPlayer from 'react-player';
import playBtn from '../assets/play-btn.png';
import pauseBtn from '../assets/pause-btn.png';


function CustomPlayer({url, divideSizeBy}) {
  const [isPlaying, setPlaying] = React.useState(false)
  const size = 1.5756 * divideSizeBy

  return (
    <div className="custom-player">
      <ReactPlayer
      url={url}
      
      playing={isPlaying}
      pip={false}
      onPause={()=>setPlaying(false)}
      playsinline={true}
      width={`${375/size}px`}
      height={`${812/size}px`}
      style={{
        border: `${10/size}px solid #5BBFDD`, 
        borderRadius: `${60/size}px`, 
        overflow: "hidden", 
        background:"#3B355D", 
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.4)",
        position: "relative"}}
      />
      <img src={!isPlaying ? playBtn : pauseBtn}
      className={!isPlaying ? "play-btn" : "pause-btn"}
      onClick={()=>setPlaying(!isPlaying)}
      style={{
          top: `${300/size}px`,
          left: !isPlaying ? `${210/size}px`: `${200/size}px`,
          width: `${100/size}px`
        }}
      alt={!isPlaying ? "play button" : "pause button"}
      />
    </div>
  
  );
}

export default CustomPlayer;
