import React from "react";
import { Helmet } from "react-helmet";
import OneButtonModal from "../../components/Modals/OneButtonModal.js";
import screen_2 from "../../assets/screen_2.png";
import primetimelogo from "../../assets/primetimelogo.png";
import WeeklySchedule from "../../components/WeeklySchedule.js";
import ClosedQuizzes from "../../components/ClosedQuizzes.js";
import DarkTitleCard from "../../components/DarkTitleCard.js";
import { Link } from "react-router-dom";
import CustomPlayer from "../../components/CustomPlayer.js";
import ChangeBackgroundOnScroll from "../../components/ChangeBackgroundOnScroll.js";

function HomePageView() {
  const [currentColor, setCurrentColor] = React.useState("#493E76");
  return (
    <div className="animate__animated animate__fadeIn">
      <Helmet>
        <title>Primetime</title>
        <meta
          name="description"
          content="Primetime är Sveriges största livefrågesport! Vi sänder frågesport live i mobilen varje dag där du kan vinna riktiga pengar. Det är helt gratis att spela!"
        />
      </Helmet>

      <ChangeBackgroundOnScroll setColor={setCurrentColor}>
        <div
          className="default-page-container emoji-image-2 "
          style={{
            backgroundColor: currentColor,
            transition: "background-color 1s",
          }}
        >
          <div className="page-content-homepage">
            <div className="homepage-start-container">
              <div className="primetime-title">
                <img
                  src={primetimelogo}
                  alt="Primetime Quiz"
                  className="primetime-logo"
                ></img>
                <div className="title-header">Sveriges största frågesport</div>
                <div className="title-subheader">
                  Live i din mobil varje kväll!
                </div>
              </div>

              <OneButtonModal
                title="Sugen på att spela?"
                text="Vi är live klockan 20.00. Vinn pengar redan ikväll!"
                btntext="Ladda ner 🤝"
                color="#2AACD3"
                destination="https://quiz.onelink.me/OGi2/primetimedl"
              />
            </div>

            <div className="first-row-container">
              <div className="hp-box" data-aos="fade-right" data-aos-delay="0">
                <h1 className="titlecard-title">
                  Välkommen till Primetime – Sveriges första och största
                  livefrågesport!
                </h1>
                <br />
                <p className="titlecard-body">
                  Vi sänder frågesport live i mobilen alla dagar klockan 20.00.
                  Varje omgång bjuder på kluriga, roliga och intressanta frågor.
                  I början är det väldigt lätt men sedan blir det svårare och
                  svårare för varje fråga.
                </p>
              </div>

              <div className="image-block-container">
                <div data-aos="fade-up" data-aos-delay="200">
                  <CustomPlayer
                    url={"https://vimeo.com/604646220/cee50981b9"}
                    divideSizeBy={1}
                  />
                  {/* <img src={screen_1} alt="img" className="game-screenshot"/> */}
                </div>
              </div>
            </div>

            <div className="second-row-container">
              <div className="image-block-container">
                <div data-aos="fade-up" data-aos-delay="200">
                  <img src={screen_2} alt="img" className="game-screenshot" />
                </div>
              </div>
              <div className="hp-box" data-aos="fade-left" data-aos-delay="0">
                <h1 className="titlecard-title">
                  Svara rätt på alla frågor och vinn pengar! 🤑
                </h1>
                <br />
                <p className="titlecard-body">
                  Svarar du rätt på alla frågor är du med och delar på kvällens
                  prispott som oftast består av riktiga pengar. Svarar du fel
                  får du en ny chans nästa omgång. Och det bästa av allt - det
                  är gratis att vara med!
                </p>
              </div>
            </div>

            <div className="box">
              <div className="hp-shortways">
                <Link to="/support" className="homepage-link-blocks">
                  <DarkTitleCard
                    emoji="👷‍♀️"
                    pretitle="Har du en"
                    title="Fråga till vår support?"
                    smallTitle={true}
                    wide={true}
                  />
                </Link>

                <Link to="/annonsering" className="homepage-link-blocks">
                  <DarkTitleCard
                    emoji="🤳"
                    pretitle="Eller kanske vill du"
                    title="Annonsera hos oss?"
                    smallTitle={true}
                    wide={true}
                  />
                </Link>
              </div>

              <div className="snd-row-container">
                <div className="snd-row-fst-col">
                  <DarkTitleCard
                    pretitle="Spana in"
                    title="Veckans schema"
                    belongsToItemBelow={true}
                  />
                  <WeeklySchedule />
                </div>
              </div>
              <div className="snd-row-container">
                <div className="snd-row-fst-col" style={{ marginTop: "14px" }}>
                  <DarkTitleCard
                    pretitle="Anmäl dig till något av våra"
                    title="Stängda quiz"
                    belongsToItemBelow={true}
                  />
                  <ClosedQuizzes />
                </div>
              </div>
            </div>
            <div className="insta-container">
              <OneButtonModal
                title="Följ oss på Instagram!"
                text="Få ledtrådar, erbjudanden och tips om temaquiz."
                btntext="Gå till Instagram 📸"
                color="#08CEB0"
                destination="https://instagram.com/primetimesverige"
              />
            </div>
          </div>
        </div>
      </ChangeBackgroundOnScroll>
    </div>
  );
}

export default HomePageView;
