import React, {useState} from 'react';
import { SliderData } from './SliderData';
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa';

const ImageSlider = ({slides}) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1) // when reaching the last slide, go back to the first.
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1) // when reaching the first slide going back, move to the back.
    }

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
        <div className="slider">
            {SliderData.map((slide, index) => {
                return (
                    <div className={index===current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (
                            <div className="slider-image-container">
                                <img src={slide.image} alt="news about primetime" className="slider-image" />
                                
                                <div className="slider-overlay">
                                    <div className="slider-constants">
                                        <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide}/>
                                        <h1 className="slides-big-hl">Primetime i media</h1>
                                        <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide}/>
                                    </div>
                                    <div className="slides-texts">
                                        <h2 className="slides-paper">{slide.paper}</h2>
                                        <h3 className="slides-text">{slide.headline}</h3>
                                        
                                        <p className="slides-body">
                                            Läs hela artikeln <a className="slides-link" href={slide.url} target="_blank" rel="noreferrer">här!</a>
                                        </p>
                                    </div>
                                </div>
                            
                            </div>
                        )}
                        
                    </div>
                )
            })}
        </div>
    )
}

export default ImageSlider
