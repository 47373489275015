import React from 'react';

// old pink: #FA8ED8 old blue: #5BBFDD

const ChangeBackgroundOnScroll = ({children, setColor}) => {
    const colors = ["#493E76", "#C771AC", "#4693AB"]

    function handleScroll() {
        const scrollPercentage = getScrollPercent()
        if (scrollPercentage <= 33) {
            setColor(colors[0])
        } else if (scrollPercentage > 33 && scrollPercentage <= 66) {
            setColor(colors[1])
        } else if (scrollPercentage > 66 && scrollPercentage < 100) {
            setColor(colors[2])
        } else {
            setColor(colors[0])
        }


    }

    function getScrollPercent() {
        var h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
    }

    React.useEffect(()=> {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        }    
    });

    return (
        <div>
            {children}
        </div>
    )
}

export default ChangeBackgroundOnScroll;