import React from 'react';
import loading from '../assets/loading.gif';

function WeeklySchedule() {
    const [schedule, setSchedule] = React.useState();

    React.useEffect(() => {
        // setSchedule([])
        fetch('/getWeeklySchedule').then(data => data.json()).then(json => setSchedule(json))
    }, [])


    return (
        <div>
            {
            schedule ? // if the schedule data is loaded, render the schedule
            <div className="schedule-container">
                {
                    schedule.length > 0 ?
                schedule.map(week => 
                <div key={schedule.indexOf(week)} className="schedule-content">
                    <div className="schedule-title-header">
                        <div className="schedule-emoji-container">
                            <div className="cm-emoji">
                                📆
                            </div>
                        </div>
                        <div className="schedule-title-n-date">
                            <div className="schedule-title">{week.title}</div>
                            <div className="schedule-date">{week.date.substr(0,10)}</div>
                        </div>
                    </div> 
                    
                    <div dangerouslySetInnerHTML={{ __html: week.content}}/>
                </div>
                )
                :
                <div className="schedule-error-body">
                    <h2>Det verkar inte finnas något schema just nu 🤥</h2>
                    <p>Senaste nytt kan du alltid hitta på vår <b><a href="https://instagram.com/primetimesverige">instagram!</a></b></p>
                </div>
                }
            </div>
            :
            <div className="schedule-container">
                <img 
                src={loading} 
                alt="Primetime loading gif"
                className="primetime-loading-gif"
                />
            </div> // if not, render a loading icon.
            }
        </div>
    )
}

export default WeeklySchedule;