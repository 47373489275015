import React from 'react';
import { Helmet } from 'react-helmet';
import {PrivacyMarkup} from '../../assets/privacy.js';
import CollapsibleModal from '../../components/CollapsibleModal';
import TitleCard from '../../components/TitleCard.js';


function PrivacyView() {
    return (
        <div>
            <Helmet>
                <title>Integritetspolicy</title>
                <meta name="description" content="Läs mer om den integritetspolicy Primetime har, och hur vi behandlar dina uppgifter."/>
            </Helmet>
            <div className="default-page-container emoji-image-1">

                <div className="faq-text-container">
                    <TitleCard 
                        title="Integritetspolicy kan låta tråkigt"
                        body={<span>men det är faktiskt <b>viktigt!</b></span>}
                        />
                
                    <CollapsibleModal 
                    emoji="👩‍⚖️"
                    title="Integritetspolicy"
                    body={<PrivacyMarkup/>}
                    expanded={true}
                    inlineMaxHeight="10000px"
                    />

                </div>
            </div>
        </div>
    )
}

export default PrivacyView;