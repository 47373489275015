import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import AdvertisersView from "./views/HeaderViews/AdvertisersView.js";
import SupportView from "./views/HeaderViews/SupportView.js";
import HomePageView from "./views/HeaderViews/HomePageView.js";
import AboutView from "./views/HeaderViews/AboutView.js";
import FindUsView from "./views/FooterViews/FindUsView.js";
import PrivacyView from "./views/FooterViews/PrivacyView.js";
import PressView from "./views/HeaderViews/PressView.js";
import WorkView from "./views/FooterViews/WorkView.js";
import ScrollToTop from "./components/ScrollToTop.js";
import QuizAnswersView from "./views/HeaderViews/QuizAnswersView.js";

function App() {
  const [currentPage, setCurrentPage] = React.useState("");

  const [headerVisible, setHeaderVisible] = React.useState(true);
  const [previousScrollPos, setPreviousScrollPos] = React.useState();
  const [currentScrollPos, setCurrentScrollPos] = React.useState();
  const [sideBarActive, setSideBarActive] = React.useState(false);

  const handleScroll = () => {
    setCurrentScrollPos(window.pageYOffset);
  };

  React.useEffect(() => {
    if (currentScrollPos > previousScrollPos && currentScrollPos > 0) {
      setHeaderVisible(false);
      setSideBarActive(false);
    } else if (currentScrollPos < previousScrollPos) {
      setHeaderVisible(true);
    }
    setPreviousScrollPos(currentScrollPos);
  }, [currentScrollPos, previousScrollPos]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className="App">
      <Router>
        <ScrollToTop setHeaderVisible={setHeaderVisible} />
        <Header
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className={
            headerVisible
              ? sideBarActive
                ? "header-container-open"
                : "header-container-closed"
              : "header-container-hidden"
          }
          sideBarActive={sideBarActive}
          setSideBarActive={setSideBarActive}
        />

        <Switch>
          <Route path="/om">
            <AboutView />
          </Route>
          <Route path="/radiofragor">
            <QuizAnswersView />
          </Route>
          <Route path="/annonsering">
            <AdvertisersView />
          </Route>
          <Route path="/Support">
            <SupportView />
          </Route>
          <Route path="/integritetspolicy">
            <PrivacyView />
          </Route>
          <Route path="/kontakt">
            <FindUsView />
          </Route>
          <Route path="/jobb">
            <WorkView />
          </Route>
          <Route path="/press">
            <PressView />
          </Route>
          {
            //den matchar första bästa, därför måste root-pathen ligga underst.
          }
          <Route path="/">
            <HomePageView />
          </Route>
        </Switch>
        <Footer currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Router>
    </div>
  );
}

export default App;
