import React from 'react';
import loading from '../assets/loading.gif';

function ClosedQuizzes() {
    const [quizzes, setQuizzes] = React.useState();

    React.useEffect(() => {
        fetch('/getActiveSignupLinks')
        .then(data => data.json())
        .then(jsonData => {
            for (let i = 0; i < jsonData.length; i++) {
                let content = jsonData[i].content
                content = content.replace("class=“cta”>", 'target="_blank" class="quiz-link">')
                content = content.replace('class="cta">', 'target="_blank" class="quiz-link">')
                content = content.replaceAll("“", "")
                content = content.replaceAll("”", "")
                jsonData[i].content = content
            }
            return jsonData
        })
        .then(items => setQuizzes(items))
    }, [])
    


    return (
        <div>
            {
            quizzes ? 
            <div className="quizzes-container">
                <div className="quizzes-title-header">
                            <div className="quiz-emoji-container">
                                <div className="cm-emoji">
                                    🤫
                                </div>
                            </div>
                            <h2>Stängda quiz</h2>
                </div>
                {
                    quizzes.length > 0 ? 
                quizzes.map(quiz => 
                <div key={quizzes.indexOf(quiz)} className="quiz-content">
                    <div className="quiz-title">{quiz.title}</div>
                    <div dangerouslySetInnerHTML={{ __html: quiz.content}}/>
                </div>
                )
                :
                <div className="titlecard-body" style={{margin: "10px 20px 20px 20px"}}>
                    Just nu verkar det inte finnas några stängda quiz att anmäla sig till! 🙈
                </div>
                }
            </div>
            :
            <div className="schedule-container">
                <img 
                src={loading} 
                alt="Primetime loading gif"
                className="primetime-loading-gif"
                />
            </div> // if not, render a loading icon.
            }
        </div>
    )

}

export default ClosedQuizzes;