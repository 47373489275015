import React from 'react';
import { Dot } from './Dot';

function CollapsibleModal({emoji, img, imgWidth, title, subtitle, body, dotColor, expanded, inlineMaxHeight, homepageModal}) {
    const [isCollapsed, setIsCollapsed] = React.useState(!expanded); 

    return (
        <div className={isCollapsed ? "cm-container" : homepageModal ?  "homepage-cm-modal" : "open-cm-container"}>
            <div className="cm-title-container"
            style={{cursor: expanded ? "default" : "pointer"}}
            onClick={expanded ? () => {} : ()=>setIsCollapsed(!isCollapsed)}>
                <div className="cm-logo-and-title-container">
                    <div className="cm-emoji-container">
                        {emoji ? 
                        <div className="cm-emoji">
                            {emoji}
                        </div>
                        :
                        <img src={img} alt="Primetime quiz logo" style={{width: imgWidth}}>
                        </img>
                        }
                    </div>
                    <div className="cm-title-text-container">
                        <h3 className="cm-subtitle-text">{subtitle}</h3>
                        <h2 className="cm-title-text">{title}</h2>
                    </div>
                </div>
                { dotColor && <Dot color={dotColor}/> }
                
            </div>
            <div className={isCollapsed ? "cm-body-container-closed" : "cm-body-container-open"} style={inlineMaxHeight ? {maxHeight: inlineMaxHeight} : {}}>
                {body}
            </div>

        </div>
    )
}


export default CollapsibleModal;