import React from 'react';
import HostCard from '../../components/Modals/HostCard';

export default function HostCardList({hostcards}) {
    return (
        <div className="card-grid">
            {hostcards.map(hostcard => {
                return <HostCard hostcard={hostcard} key={hostcard.id} />
            })}
        </div>
    )
}
