import React, { useRef } from "react";
import emailjs from "emailjs-com";
import loading from "../assets/loading.gif";

const ContactForm = ({
  title,
  description,
  ticketType,
  backupEmail,
  username,
}) => {
  const form = useRef();
  const [isSent, setIsSent] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);
  const [errorReceived, setErrorReceived] = React.useState(false);

  function sendEmail(e) {
    emailjs
      .sendForm(
        "service_zdl55q2",
        ticketType === "support" ? "support-ticket" : "business-ticket",
        form.current,
        "user_9qJDgy5xia1V3Mr6a5vuF"
      )
      .then(
        (result) => {
          setIsSent(true);
          setIsPending(false);
          console.log(result.text);
        },
        (error) => {
          setErrorReceived(true);
          setIsPending(false);
          console.log("Errror!");
          console.log(error.text);
        }
      );
    form.current.reset();
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsPending(true);
    sendEmail(e);
  }

  return (
    <div className="contact-form-super-container">
      <div className="contact-form-container" id="contact-form-id">
        {!isSent && !errorReceived ? (
          isPending ? (
            <div className="mail-sent-confirmation">
              <img
                src={loading}
                alt="Primetime loading gif"
                className="primetime-loading-gif"
              />
            </div>
          ) : (
            <div style={{ height: "100%", width: "100%" }}>
              <h1 className="contact-form-title">{title}</h1>
              <h3 className="contact-form-subtitle">{description}</h3>
              <form ref={form} className="contact-form" onSubmit={handleSubmit}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-input"
                  required
                  placeholder={username ? "Användarnamn i appen" : "Namn"}
                ></input>
                {username && (
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-input"
                    required
                    placeholder={username ? "Telefonnummer" : "Telefonnummer"}
                  ></input>
                )}
                {username && (
                  <select
                    id="problem"
                    name="problem"
                    className="select-input"
                    required
                  >
                    <option value="">Vad handlar ditt ärende om?</option>
                    <option value="Vinster och utbetalningar">
                      Vinster och utbetalningar
                    </option>
                    <option value="Problem med ljud/video">
                      Problem med ljud/video
                    </option>
                    <option value="Problem med att svara i showen">
                      Problem med att svara i showen
                    </option>
                    <option value="Pokaljakten och pokaler">
                      Pokaljakten och pokaler
                    </option>
                    <option value="Problem med chatten">
                      Problem med chatten
                    </option>
                    <option value="Problem med livboj">
                      Problem med livboj
                    </option>
                    <option value="Startsidan “lobby” i appen">
                      Startsidan “lobbyn” i appen
                    </option>
                    <option value="Statistik i appen">Statistik i appen</option>
                    <option value="Problem med pushnotiser">
                      Problem med pushnotiser
                    </option>
                    <option value="Ändra mitt konto">Ändra mitt konto</option>
                    <option value="Övrigt">Övrigt</option>
                    <option value="Skicka kärlek till oss!🥰">
                      Skicka kärlek till oss!🥰
                    </option>
                  </select>
                )}
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-input"
                  required
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                  title="Skriv in en giltlig e-postadress"
                  placeholder="E-postadress"
                ></input>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  className="form-message-input"
                  required
                  placeholder={
                    username
                      ? "Meddelande (beskriv utförligt ditt ärende)"
                      : "Meddelande"
                  }
                ></textarea>
                <input
                  type="submit"
                  className="form-submit-btn"
                  value="Skicka 💌"
                ></input>
              </form>
            </div>
          )
        ) : errorReceived ? (
          <div className="mail-sent-confirmation">
            <h1>Ojdå! Något gick fel.</h1>
            <p>
              Prova skicka mailet direkt till oss på <code>{backupEmail}</code>
            </p>
            <h1 style={{ fontSize: "64px" }}>🤭</h1>
          </div>
        ) : (
          <div className="mail-sent-confirmation">
            <h1>Skickat!</h1>
            <p>Vi svarar på ditt mail så snart som möjligt.</p>
            <h1 style={{ fontSize: "64px" }}>🥳</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
