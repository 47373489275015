import React from 'react';
import { Helmet } from 'react-helmet';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CollapsibleModal from '../../components/CollapsibleModal';
import studio2 from '../../assets/studio2.jpeg';
import TitleCard from '../../components/TitleCard.js';

function WorkView() {

    const [isClicked, setIsClicked] = React.useState(false);

    React.useEffect(() => {
        if (isClicked) {
            setTimeout(() => {
                triggerOff();
            }, 2000);
        }

    },[isClicked])

    const trigger = () => {
        setIsClicked(true);
       };

    const triggerOff = () => {
        setIsClicked(false);
        };

    return (
        <div>
            <Helmet>
                <title>Jobba hos oss</title>
                <meta name="description" content="Vill du jobba på Primetime? Sök programledare, säljare, utvecklare eller något helt annat! Vi tar löpande emot praktikanter."/>
            </Helmet>
            <div className="default-page-container emoji-image-1">
                

                <div className="faq-text-container">
                    <TitleCard 
                title="Vill du jobba hos oss?"
                body="Vi letar hela tiden efter nya kollegor. Släng iväg ett email vetja! Eller scrolla ner för att läsa mer om vilka möjligheter som finns."/>
                    
                    <CollapsibleModal 
                    emoji="👩‍🎓"
                    title="Praktik"
                    body={<p className="expanded-modal-body">Pluggar du och vill göra din praktik på Primetime? Vi tar löpande emot praktikanter och många väljer att jobba kvar efter sin tid hos oss. De som praktiserat hos oss tidigare har bland annat pluggat media, kommunikation, TV-produktion och copywriter. Skicka oss ditt CV och ett kort personligt brev.
                    </p>}
                
                    />

                    <CollapsibleModal 
                    emoji="🧑‍✈️"
                    title="Programledare"
                    body={<p className="expanded-modal-body">Är du intresserad av att bli en av Primetimes programledare? Vi letar alltid efter glada och ambitiösa programledare som pratar svenska, norska, finska, danska eller tyska. Spela in en kort video där du presenterar dig själv och skicka till oss. Det räcker med några minuters inspelat material där du till exempel kan berätta om vad du gjorde i somras eller ett intresse du har. Observera att vi sänder alla länder från Stockholm.
                    </p>}
                    
                    />

                    <CollapsibleModal 
                    emoji="🦄"
                    title="Spontanansökan"
                    body={<p className="expanded-modal-body">Är du intresserad av en tjänst som inte listas ovan så är du välkommen att skicka in en spontanansökan med CV och personligt brev.</p>}
                    
                    />
                                {/* Adjusted to account for modal margin. */}
                                <div style={{marginTop: "30px"}}> 
                        <div className="modal-container">
                            <div className="modal-content">
                                <h2 className="modal-title-text">
                                    Kontakta oss direkt! 😎 
                                </h2>
                                
                                <CopyToClipboard text="martin.palm@primetimequiz.com">
                                    <div className={isClicked===true ? "modal-btn animate__animated animate__bounce" : "modal-btn"}
                                    onClick={trigger}
                                    style={{fontSize: "small"}}>
                                        {!isClicked ? "martin.palm@primetimequiz.com" : "Kopierat!"}
                                    </div>
                                </CopyToClipboard>  
                            </div>
                        </div>
                    </div>

                <img src={studio2} className="big-picture" alt="primetime studio during broadcast"></img>
                </div>
                
        </div>
    </div>
    )
};

export default WorkView;