import React from "react";
import { Helmet } from "react-helmet";
import small_logo from "../../assets/small_logo.png";
import CollapsibleModal from "../../components/CollapsibleModal.js";
import TitleCard from "../../components/TitleCard";
import ContactForm from "../../components/ContactForm";

function SupportView() {
  return (
    <div className="default-page-container emoji-image-2">
      <Helmet>
        <title>Primetime Support</title>
        <meta
          name="description"
          content="Kunde ditt svar inte registreras? Kan du inte ta ut dina pengar? Hjälp finns! I vår support/FAQ finns det svar på de vanligaste frågorna vi får om Primetime."
        />
      </Helmet>

      <div className="faq-text-container">
        <TitleCard
          title="Vanliga frågor 👀"
          body="Här finns svar på några av de vanligaste frågorna vi får."
        />

        <CollapsibleModal
          emoji="🤠"
          title="Spelets gång"
          subtitle="Har du frågor om.."
          body={SpeletsGang()}
        />

        {/* <CollapsibleModal
          emoji="😋"
          title="Jackpottfrågan"
          subtitle="En fundering om..."
          body={JackpotMarkup()}
        /> */}

        <CollapsibleModal
          emoji="🤔"
          title="Primetimekonto"
          subtitle="eller undrar du om ditt.."
          body={DittPrimetimeKonto()}
        />

        <CollapsibleModal
          img={small_logo}
          imgWidth="50px"
          title="Om Primetime"
          subtitle="eller har du andra frågor.."
          body={OmPrimetime()}
        />
      </div>

      <ContactForm
        title="Hittade du inte svaret på din fråga?"
        description="Då kan du höra av dig till oss här."
        ticketType="support"
        backupEmail="support@primetimequiz.se"
        username={true}
      />
    </div>
  );
}

export default SupportView;

const JackpotMarkup = () => {
  return (
    <div>
      <h3 className="faq-question" style={{ marginTop: "0px" }}>
        Jackpottfrågan
      </h3>
      <p className="faq-answer">
        För att delta i jackpottfrågan skall du skicka rätt svar till
        telefonnummer 72670 under tävlingens anmälningsperiod. För att vinna ska
        du svara när Primetime ringer upp live under sändning samt svara rätt på
        quizfrågan.
        <br />
        <br />
        Nedan är villkoren för Jackpottfrågan.
      </p>
      <h3 className="faq-question">Primetimes villkor för JACKPOTTFRÅGAN</h3>
      <p className="faq-answer">
        <ul>
          <li>Eventuell skatt på vinsten betalas av vinnaren.</li>
          <li>
            Om inte annat anges ansvarar Primetime inte för extra kostnader i
            samband med vinsten.
          </li>
          <li>
            Vinnarens namn, bild och röst kan komma att användas för
            kommersiellt syfte.
          </li>
          <li>
            Vinnaren kan inte byta ut vinsten mot någon ekonomisk motsvarighet,
            ersättning eller överlåta vinsten till någon annan.
          </li>
          <li>
            Primetime förbehåller sig rätten att när som helst under
            tävlingsperioden ändra tävlingen, regler och dess innehåll.
          </li>
          <li>
            Primetime är inte ansvariga för tekniska fel rörande telefon,
            nätverk eller operatör.
          </li>
          <li>
            Anställda på Primetime, deras kunder, samarbetspartners eller
            återförsäljare samt deras närmaste anhöriga och familjemedlemmar får
            inte delta i tävlingar som arrangeras av Primetime.
          </li>
          <li>Endast ett pris per familj/hushåll och tävling.</li>
          <li>Endast 1 (ETT) tävlingsbidrag/dag om inget annat anges.</li>
          <li>Kontantvinster betalas 1–3 månader från vinsttillfället.</li>
        </ul>
      </p>
      <h3 className="faq-question">PUNKT 1: ALLMÄNT</h3>
      <p className="faq-answer">
        Genom att aktivt delta i JACKPOTTFRÅGAN godkänner du Villkoren.
      </p>
      <h3 className="faq-question">PUNKT 2: ARRANGÖR</h3>
      <p className="faq-answer">
        “JACKPOTTFRÅGAN” arrangeras av Sventertainment AB org. nr. 559155-8464,
        Drottninggatan 95 A, 113 60 Stockholm, nedan kallad ”Primetime”.
      </p>
      <h3 className="faq-question">PUNKT 3: ANMÄLNINGSPERIOD</h3>
      <p className="faq-answer">
        Deltagare kan anmäla sitt deltagande i denna omgång av JACKPOTTFRÅGAN
        under följande tidsperiod (”Anmälningsperiod”):
        <br />
        <br />
        <b>Startdatum: Lördag 22 april 2023 kl. 20.00</b>
        <br />
        <b>Slutdatum: Lördag 27 maj 2023 kl. 18.00</b>
        <br />
        <br />
        Under hela kampanjperioden har du chans att vinna en gång i veckan under
        lördagens livesändning som startar 20:00, anmälan är öppen lördag 20.00
        – lördag 18.00.
        <br />
        <br />
        Primetime förbehåller sig rätten att sända JACKPOTTFRÅGAN när som helst.
      </p>
      <h3 className="faq-question">
        PUNKT 4: DETALJER FÖR ATT DELTA OCH VINNA
      </h3>
      <p className="faq-answer">
        Efter Anmälningsperiodens start kommer Primetime tillkännage priset för
        omgången av JACKPOTTFRÅGAN. Primetime kan tillkännage priset när som
        helst under Anmälningsperioden i sin sändning, på sin webbplats, sociala
        medier, via SMS-meddelande eller någon annan kommunikationsform. Det
        finns ingen gräns för värdet av priset.
        <br />
        <br />
        Efter Anmälningsperiodens slut kommer en av de inkomna och giltiga
        anmälningar att väljas ut. Det sker genom en automatiserad slumpmässig
        dragning som administreras av programvara från tredje part. Utvald
        deltagare kommer kontaktas på det telefonnummer som tillhandahållits av
        deltagaren i och med anmälan. Kontakten sker inom tre (3) timmar efter
        Anmälningsperiodens slut och när Primetime ringer så syns ”Inget
        uppringnings-ID” på displayen.
        <br />
        <br />
        För att vinna Jackpotten ska den slumpmässigt utvalda deltagaren först
        och främst svara inom sex ringsignaler. Deltagaren måste svara innan den
        sjätte ringsignalen tystnat. De sex ringsignalerna kommer vid tvivel att
        bedömas med hjälp av Primetimes ljudinspelning. Därefter ska deltagaren
        svara korrekt på den aktuella omgångens quizfråga för att vinna priset.
        <br />
        <br />
        Deltagaren kommer inte vinna priset om personen:
        <ul>
          <li>inte svarar i telefon överhuvudtaget; eller</li>
          <li>
            missar att svara inom sex ringsignaler (enligt Primetimes
            ljudinspelning); eller
          </li>
          <li>svarar fel på quizfrågan; eller</li>
          <li>inte säger något alls; eller</li>
          <li>
            blir bortkopplad av någon anledning som ligger utanför Primetimes
            kontroll
          </li>
        </ul>
        Finaltillfället sker i direktsänt i Primetimes app. Vinnarens namn och
        hemstad kan komma att publiceras på Primetimes sändning, webbplats och
        sociala medieplattformar efter att vinnaren tillkännagivits i appen.
        <br />
        <br />
        Om en deltagare inte vill delta i Primetimes livesändning eller om något
        annat fel inträffar före eller under samtalet, förlorar deltagaren sin
        chans att vinna priset. Primetimes produktionsteam förbehåller sig även
        rätten att neka en deltagare medverkan om produktionsteamet misstänker
        att deltagaren deltar olagligt eller kan riskera att kränka Primetimes
        publik. En annan deltagare vars anmälan är giltig kan således komma att
        väljas ut slumpmässigt.
        <br />
        <br />
        Om en deltagare i Primetimes livesändning använder ett språk som inte
        tolereras av Primetime, uttrycker sig på ett sätt som kan strida mot
        lag, beter sig på ett sätt som kan skada Primetimes rykte så förbehålles
        rätten att avbryta samtalet med deltagaren. Deltagaren diskvalificeras
        och mister chansen att vinna priset. Så kan även komma att ske om
        deltagaren på annat sätt bryter mot Villkoren.
      </p>
      <h3 className="faq-question">PUNKT 5: ANMÄLAN</h3>
      <p className="faq-answer">
        Anmälan sker genom att deltagaren skickar ett SMS med texten “en” “två”
        eller “tre” till 72670. Varianterna “ett” och “tva” godkänns också och
        räknas som en giltig anmälan.
        <br />
        <br />
        Anmälan måste inkomma innan Anmälningsperiodens slut. Alla SMS som
        mottagits innan eller efter Anmälningsperioden är ogiltiga, men kan
        fortfarande komma att debiteras avsändaren. Primetime ansvarar inte för
        tekniska fel som kan försena eller förhindra leverans av anmälan.
        <br />
        <br />
        Om en anmälan är korrekt får deltagaren ett automatiskt svars-SMS som
        bekräftar deltagande. Om en anmälan däremot inte innehåller giltigt
        kodord, eller om SMS:et inte skickas till korrekt nummer, är anmälan
        inte giltig och kommer således inte inkluderas i tävlingen.
      </p>
      <h3 className="faq-question">PUNKT 6: KOSTNADER</h3>
      <p className="faq-answer">
        Deltagaren är ansvarig för samtliga kostnader kopplade till ett
        deltagande i en omgång av JACKPOTTFRÅGAN. En (1) anmälan till en omgång
        via SMS kostar 17 kr + ord. operatörsavgift. Notera att deltagaren måste
        ha tillstånd från sin mobiloperatör för den här typen av betaltjänster.
      </p>
      <h3 className="faq-question">PUNKT 7: RESTRIKTIONER OCH BEGRÄNSNINGAR</h3>
      <p className="faq-answer">
        Deltagande i JACKPOTTFRÅGAN är endast öppen för personer som:
        <ul>
          <li>är 18 år eller äldre</li>
          <li>bor i Sverige</li>
          <li>inte är anställda av Primetime eller dennes närmaste familj</li>
          <li>
            inte är anställd hos eventuell sponsor eller prisleverantörer från
            tredje part eller deras närmaste familj
          </li>
        </ul>
        En deltagare kan endast göra en (1) anmälan till en omgång av
        JACKPOTTFRÅGAN per dygn. Skickar deltagaren fler än ett sms per dygn så
        får denne ett automatiskt svars-sms som beskriver att en anmälan redan
        är registrerad för aktuellt dygn. Deltagaren kan endast debiteras för
        ett sms per dygn.
        <br />
        <br />
        Deltagare måste ha tillstånd från sin mobiloperatör för den här typen av
        betaltjänster.
      </p>
      <h3 className="faq-question">PUNKT 8: PRISET (VINSTEN)</h3>
      <p className="faq-answer">
        Priset kommer alltid att vara ett kontantbelopp och betalas ut via
        banköverföring. Utbetalning av priset är förutsatt att vinnaren
        tillhandahåller nödvändig personlig information och/eller bevis på
        adress och intern kontroll av efterlevnad. Priset är inte överförbart
        till annan person och kan inte bytas ut mot annan vara/tjänst. Priset
        betalas ut senast 90 dagar efter vinsttillfället.
        <br></br>
        <br />
        Om det framgår att vinnaren har åsidosatt Villkoren efter det att priset
        betalats ut så förbehåller Primetime rätten att dra tillbaka priset från
        vinnaren. Vinnaren har då ingen rätt till ytterligare belöning eller
        ersättning. Primetime förbehåller sig rätten att utesluta personer som
        bryter mot Villkoren.
      </p>
      <h3 className="faq-question">PUNKT 9: DATASKYDD</h3>
      <p className="faq-answer">
        Genom att aktivt delta i JACKPOTTFRÅGAN så godkänner deltagaren
        Villkoren. Vid anmälan till en omgång av JACKPOTTFRÅGAN så lämnar
        deltagaren information om sitt telefonnummer till Primetime.
        Telefonnumret kommer att behandlas av Primetime endast för att kunna
        administrera JACKPOTTFRÅGAN, och för att betala ut eventuell vinst.
        <br></br>
        <br />
        Genom att delta i en omgång av JACKPOTTFRÅGAN kan deltagaren också komma
        att erhålla marknadsföringskommunikation från Primetime via SMS.
        Deltagaren kan alltid stoppa dessa marknadsförings-SMS och avregistera
        sig, det genom att följa de instruktioner som medföljer i SMS:et. Ett
        avregisterings-SMS kostar som ett vanligt standard-SMS.
        <br></br>
        <br />
        Primetime sparar de uppgifter som lämnats och som rör deltagande i 12
        månader.
        <br></br>
        <br />
        Den deltagare som får chans att vinna Priset kommer att spelas in.
        Inspelningen kommer att används i Primetimes livesändning, och
        eventuellt också i efterhand som marknadsföring av Primetime. Vinnarens
        namn och hemstad kan också komma att publiceras på Primetimes webbplats
        och sociala medieplattformar efter att vinnaren har tillkännagivits i
        livesändningen.
        <br></br>
        <br />
        Primetime kommer bara att behandla personuppgifter när vi har en laglig
        grund att göra det. Behandlingen av en deltagares personuppgifter är
        framförallt nödvändig för att vi ska kunna fullgöra det avtal vi ingår
        med deltagaren i och med dennes anmälan, d.v.s. administrera
        JACKPOTTFRÅGAN. Primetime äger de datauppgifter som du som deltagare
        lämnar. Tredje part bistår Primetime med hanteringslogistik s.k.
        personuppgiftsbiträde. Primetime är således personuppgiftsansvarig.
        <br></br>
        <br />I vissa fall är vår behandling av personuppgifter nödvändig för
        ändamål som rör vårt, eller annan tredje parts, berättigade intresse. I
        samtliga dessa fall har vi gjort bedömningen att våra eller tredje parts
        intressen av att vi behandlar uppgifterna väger tyngre än någons
        intresse av att vi inte gör så.
      </p>
      <h3 className="faq-question">PUNKT 10: ÖVRIGA VILLKOR</h3>
      <p className="faq-answer">
        Primetime accepterar endast deltagande och anmälningar som är i enlighet
        med Villkoren. Produktionsteamet kan diskvalificera deltagare om det
        misstänks att deltagaren har brutit mot Villkoren. Primetimes beslut om
        val av vinnaren är slutligt och produktionsteamet deltar inte i någon
        korrespondens eller diskussion om detta med någon.
        <br></br>
        <br />
        Primetime ansvarar inte för operatörers tekniska problem, exempelvis
        fördröjning i ditt mobila nätverk, vilket kan försena eller förhindra
        deltagande.
        <br></br>
        <br />
        Primetime har rätt att ändra och avsluta JACKPOTTFRÅGAN om det uppkommer
        omständigheter utanför företagets kontroll.
      </p>
      <h3 className="faq-question">PUNKT 11: KONTAKTDETALJER</h3>
      <p className="faq-answer">
        Om du har några frågor så är du välkommen att kontakta Primetime via
        formuläret längre ner på sidan.
      </p>
    </div>
  );
};

const SpeletsGang = () => {
  return (
    <div>
      <h3 className="faq-question" style={{ marginTop: "0px" }}>
        Hur spelar jag?
      </h3>
      <p className="faq-answer">
        Primetime är helt gratis att spela, så när du har laddat ner appen (och
        förslagsvis slagit på pushnotiser) är det bara att vänta på nästa
        omgång. Du ser på startbilden i Primetime-appen när nästa omgång är.
        Alla spelare spelar samtidigt mot varandra så det gäller att inte missa
        spelomgångens första fråga. Svarar du fel, eller missar att svara på en
        fråga, är du ute för den omgången men kan fortsätta titta och kanske
        lära dig något nytt! Svarar du rätt på alla 11 frågor är du med och
        delar på vinstpotten.
      </p>
      <h3 className="faq-question">Hur funkar livbojarna?</h3>
      <p className="faq-answer">
        Livbojen är din räddning på de extra kluriga frågorna. Svarar du fel på
        en fråga så kan du, fram till och med fråga sju, välja att använda en
        livboj efter det att du har svarat fel. Trycker du på livbojen går du
        automatiskt vidare till nästa fråga. Du kan använda en livboj per
        omgång.
      </p>
      <h3 className="faq-question">Hur får jag livbojar?</h3>
      <p className="faq-answer">
        Livbojar får du när du bjuder in vänner till spelet. När de har
        registrerat sig får du din livboj. Skulle du inte få en livboj fast din
        vän registrerat sig så be vännen att skicka ett meddelande till oss via
        formuläret nedan med båda era användarnamn så löser vi det. Du får också
        en livboj när du spelar fyra dagar i rad, och ibland delar vi ut
        livbojar till alla som spelar en viss sändning. Se till att spela varje
        omgång helt enkelt!
      </p>
      <h3 className="faq-question">
        Varför kan jag inte skriva i chatten längre? Mina chattmeddelanden
        verkar inte komma fram?
      </h3>
      <p className="faq-answer">
        Om du har blivit blockerad från att chatta beror det på att du gjort
        något som vi inte tyckte var okej. Primetimes chatt är en plats där alla
        ska känna sig välkomna. Hat, hot, rasism, sexism, homofobi och allmänt
        tråkig ton accepteras inte och efter varje sändning blockerar vi spelare
        som inte sköter sig. Om dina meddelanden inte alltid syns i chatten
        behöver det inte bero på att du är blockad. När det skrivs många
        chattmeddelanden på en och samma gång kan vi bara släppa igenom ett
        visst antal för att chattens tempo ska gå att läsa. Testa att skriva
        något när färre personer är inne så ska du se att det funkar!
      </p>
      <h3 className="faq-question">
        Det står att mitt svar inte kunde registreras på grund av dålig
        anslutning, men min anslutning är ju toppen?
      </h3>
      <p className="faq-answer">
        Vår strömningstjänst är känslig för störningar i uppkopplingen, även
        kortvariga sådana som du själv kanske inte märker av. Om du har problem
        med att dina svar inte registreras kan du testa att byta mellan wif-fi
        och ditt mobilnät för att undersöka vilket som fungerar bäst.
      </p>
    </div>
  );
};

const DittPrimetimeKonto = () => {
  return (
    <div>
      <h3 className="faq-question">
        Hur anmäler jag mig till stängda specialquiz?
      </h3>
      <p className="faq-answer">
        När vi har stängda specialquiz lägger vi alltid ut en anmälningslänk i
        din inbox i Primetime-appen några dagar innan sändning. Spana in
        startsidan här för att se om det finns något stängt quiz att anmäla sig
        till!
      </p>
      <h3 className="faq-question">Kan jag byta användarnamn?</h3>
      <p className="faq-answer">
        Ja! Skicka ditt nuvarande användarnamn, telefonnummer och nya
        användarnamn genom formuläret nedan så hjälper vi dig så fort vi kan.
      </p>
      <h3 className="faq-question">Jag har inte fått mina vinstpengar än.</h3>
      <p className="faq-answer">
        Det kan ta upp till tre veckor för dina vinstpengar att betalas ut till
        det konto du angivit. Om tre veckor har gått och du ännu inte fått
        pengarna kan du kontakta oss genom kontaktformuläret nedan.
      </p>
    </div>
  );
};

const OmPrimetime = () => {
  return (
    <div>
      <h3 className="faq-question">Vilka driver Primetime?</h3>
      <p className="faq-answer">
        Bolaget bakom Primetime heter Sventertainment AB. Vi är ett nystartat
        bolag som brinner för livefrågesport helt enkelt.
      </p>
      <h3 className="faq-question">Var kommer pengarna ifrån?</h3>
      <p className="faq-answer">
        Ibland har vi samarbeten med andra företag och då står de för
        vinstpotten. Vi är alltid tydliga med att sändningen sker i samarbete
        med något annat företag om det är så. I de flesta fall kommer pengarna
        direkt från Primetime.
      </p>
      <h3 className="faq-question">Hur funkar det med vinstskatt?</h3>
      <p className="faq-answer">
        Skatter och regler är snåriga som de är och när vi dessutom skapat en ny
        kategori gör det inte saken lättare. Vi tolkar lagen som att vi inte går
        under lotterilagen utan ska kategoriseras som en tävling och där är vi
        skyldiga att redovisa de ersättningar som övergår 1000 sek till
        skattemyndigheten och därmed är vi också skyldiga att betala
        arbetsgivaravgift.
      </p>
      <h3 className="faq-question">Vad är länken till appen egentligen?</h3>
      <p className="faq-answer">
        iOS:{" "}
        <a
          href="https://itunes.apple.com/se/app/id1358438497?mt=8"
          target="_blank"
          rel="noreferrer"
        >
          Klicka här
        </a>
        <br />
        Android:{" "}
        <a
          href="https://play.google.com/store/apps/details?id=se.sventertainment.primetime"
          target="_blank"
          rel="noreferrer"
        >
          Klicka här
        </a>
        <br />
        Huawei:{" "}
        <a
          href="https://urldre.cloud.huawei.com/dxyIlOlLH2"
          target="_blank"
          rel="noreferrer"
        >
          Klicka här
        </a>
      </p>
    </div>
  );
};
