import React from "react";
import { Helmet } from "react-helmet";
import TitleCard from "../../components/TitleCard.js";
import ImageSlider from "../../components/ImageSlider";
import { SliderData } from "../../components/SliderData";
import CollapsibleModal from "../../components/CollapsibleModal.js";

function PressView() {
  return (
    <div className="default-page-container emoji-image-1">
      <Helmet>
        <title>Primetime for advertisers</title>
        <meta
          name="description"
          content="Primetime är en effektiv annonsyta där ditt företag kan synas i en sponsrad sändning. Vi erbjuder även företagsquiz till er kick-off!"
        />
      </Helmet>

      <div className="press-container">
        <ImageSlider slides={SliderData} />

        <TitleCard
          title="Primetime i media 😎"
          body="Låt oss få skryta lite om allt som hänt oss!"
        />

        <CollapsibleModal
          emoji="✍️"
          title="Här har vi nämnts..."
          body={<ViNämns />}
        />

        <CollapsibleModal
          emoji="🏆"
          title="Visste du att..."
          body={<VissteDuAtt />}
        />
      </div>
    </div>
  );
}

const ViNämns = () => {
  return (
    <p className="expanded-modal-body">
      <b>Dagens Media</b> –{" "}
      <a
        href="https://www.dagensmedia.se/medier/digitalt/har-ar-vinnarna-i-mixx-awards/"
        target="_blank"
        rel="noreferrer"
      >
        Här är vinnarna i Mixx Awards
      </a>
      <br />
      <br />
      <b>Dagens Media</b> –{" "}
      <a
        href="https://www.dagensmedia.se/medier/digitalt/primetime-far-nya-agare/"
        target="_blank"
        rel="noreferrer"
      >
        Primetime får nya ägare
      </a>
      <br />
      <br />
      <b>Expressen</b> –{" "}
      <a
        href="https://www.expressen.se/dinapengar/tech/primetimes-grundare-siktar-pa-att-bli-ledande-i-varlden/"
        target="_blank"
        rel="noreferrer"
      >
        Primetime siktar på att bli ledande i världen
      </a>
      <br />
      <br />
      <b>Dagens industri</b> –{" "}
      <a
        href="https://www.di.se/digital/mag-koper-fragesportsappen-primetime-aktien-rusar/"
        target="_blank"
        rel="noreferrer"
      >
        Mag köper frågesportsappen Primetime – aktien rusar
      </a>
      <br />
      <br />
      <b>Dagens Media</b> –{" "}
      <a
        href="https://www.dagensmedia.se/medier/rorligt/nent-planerar-primetime-sandning-med-tv3-s-hasse-aro/"
        target="_blank"
        rel="noreferrer"
      >
        Nent planerar Primetime-sändning med TV3:s Hasse Aro
      </a>
      <br />
      <br />
      <b>Aftonbladet TV</b> –{" "}
      <a
        href="https://tv.aftonbladet.se/video/273072/fraagespelets-succe-se-hela-intervjun"
        target="_blank"
        rel="noreferrer"
      >
        Frågespelets succé
      </a>
      <br />
    </p>
  );
};

const VissteDuAtt = () => {
  return (
    <ul>
      <li>Primetime vann Bästa Digitala Annonsmiljö på Mixx Awards 2022.</li>
      <li>Primetime vann juryns specialpris i Kristallen 2019.</li>
      <li>Primetime har över 2,5 miljoner nedladdningar i Norden.</li>
      <li>Primetime har kört över 3000 liveshower sedan starten 2018.</li>
      <li>Primetime har spelats fler än 100 miljoner gånger.</li>
      <li>
        Hittills har ni spelare skänkt över 1 miljon kronor till välgörenhet.
      </li>
    </ul>
  );
};

export default PressView;
