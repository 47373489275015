import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import facebooklogo from '../assets/socialmedia/facebooklogo.png';
import instagramlogo from '../assets/socialmedia/instagramlogo.png';
import linkedinlogo from '../assets/socialmedia/linkedinlogo.png';


function Footer({currentPage, setCurrentPage}) {

    const footerItems = ["Integritetspolicy", "Kontakta oss", "Jobba hos oss"]
    let currentPath = useLocation();

    React.useEffect(() => {
        matchItemToPath()
    })

    const matchItemToPath = () => {
        if (currentPath.pathname === "/integritetspolicy") {
            setCurrentPage("Integritetspolicy")
        } else if (currentPath.pathname === "/kontakt") {
            setCurrentPage("Kontakta oss")
        } else if (currentPath.pathname === "/jobb") {
            setCurrentPage("Jobb")
        }
    }

    return (
        <nav>
            <div className="footer-container">
                <div className="footer-items">
                    {
                    footerItems.map((footerItem) => 
                        <div className="footer-item" key={footerItems.indexOf(footerItem)}>
                            <Link 
                                to={
                                    footerItem === "Jobba hos oss" ? "/jobb" :  footerItem === "Kontakta oss" ? "/kontakt" :
                                    `/${footerItem.toLowerCase().replace(/ /g,'').replace(/ä|å|ö/g, (letter) => { return letter === "å" ? "a" : letter === "ä" ? "a" : letter === "ö" ? "o" : ""})}`
                                }
                                className={currentPage === footerItem ? "selected-footer-link" : "unselected-footer-link"}
                                
                            >
                                {footerItem}
                            </Link>
                        </div>
                    )
                }
                </div>
                <div className="footer-some-icons">
                    <a href="https://www.facebook.com/primetimequiz" target="_blank" rel="noreferrer">
                        <img src={facebooklogo} className="some-img" alt="Primetime facebook logo"></img>
                    </a>
                    
                    <a href="https://www.instagram.com/primetimesverige/" target="_blank" rel="noreferrer">
                        <img src={instagramlogo} className="some-img" alt="Primetime instagram logo"></img>
                    </a>
                    <a href="https://www.linkedin.com/company/primetimesweden/" target="_blank" rel="noreferrer">
                    <img src={linkedinlogo} className="some-img" alt="Primetime linkedin logo"></img>
                    </a>
                    
                    
                </div>
            </div>
        </nav>
    )

}


export default Footer;