import React from 'react';

function DarkTitleCard({title, pretitle, wide, belongsToItemBelow, smallTitle, emoji}) {
    return (
        <div className={wide ? "dtc-wide" : "dtc-container" } style={(belongsToItemBelow && {borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", marginBottom: "0px"}) || (emoji && {cursor: "pointer"})}>
                <div className="dtc-content" >
                    {emoji &&
                    <div className="dtc-emoji-container">
                            <div className="dtc-emoji">
                                {emoji}
                            </div>
                    </div>
                    }
                    <div className="dtc-text-container">
                        <h3 className="dtc-pretitle">{pretitle}</h3>
                        <h2 className="dtc-title" style={smallTitle && {fontSize: "large"}}>{title}</h2>
                    </div>
                </div>
        </div>
    )
}

export default DarkTitleCard;