import React from "react";
import { Helmet } from "react-helmet";
import CollapsibleModal from "../../components/CollapsibleModal.js";
import TitleCard from "../../components/TitleCard.js";
import ContactForm from "../../components/ContactForm.js";
import ScrollToContactForm from "../../components/ScrollToContactForm.js";
import OneButtonModal from "../../components/Modals/OneButtonModal.js";

function QuizAnswersView() {
  return (
    <div className="default-page-container emoji-image-1">
      <Helmet>
        <title>Answers to radio commercial questions</title>
        <meta
          name="description"
          content="Hörde du nyss reklam för Primetime på din radiokanal? Här hittar du svaren på frågorna!"
        />
      </Helmet>

      <div className="advertisers-container">
        <TitleCard
          title="Hur många rätt fick du? 🔎"
          body="Här nedanför hittar du svaren till våra snabbquiz på radio."
        />
        <OneButtonModal
          title="Sugen på att spela?"
          text="Vi är live klockan 20.00. Vinn pengar redan ikväll!"
          btntext="Ladda ner 🤝"
          color="#2AACD3"
          destination="http://stats.sventertainment.se/campaign-redirect.html?t=https%3A%2F%2Fprimetimequiz.page.link%2Fhemsidaradio"
        />

        <CollapsibleModal
          emoji="🎸"
          title="Rockklassiker"
          body={<Rockklassiker />}
        />
        <CollapsibleModal
          emoji="🕺"
          title="MIX Megapol"
          body={<MixMegapol />}
        />
        <CollapsibleModal emoji="💃🏼" title="NRJ" body={<MixMegapol />} />
        <CollapsibleModal
          emoji="🎙"
          title="Radio Nostalgi"
          body={<RadioNostalgi />}
        />
      </div>
    </div>
  );
}

export default QuizAnswersView;

const Rockklassiker = () => {
  return (
    <div>
      <ol>
        <li>
          Iron Maidens maskot heter <b>Eddie</b>.
        </li>
        <li>
          Metallicas trummis heter <b>Lars Ulrich</b>.
        </li>
        <li>
          "800 grader" sjungs av <b>Ebba Grön</b>.
        </li>
      </ol>
      <ol>
        <li>
          Bruce Springsteen kallas <b>"The Boss"</b>.
        </li>
        <li>
          Meatloaf <b>lever inte</b>.
        </li>
        <li>
          "I Love Rock ‘N Roll" sjungs av <b>Joan Jett & the Blackhearts</b>.
        </li>
      </ol>
      <ol>
        <li>
          Aerosmiths sångare heter <b>Steven Tyler</b>.
        </li>
        <li>
          AC/DC kommer från <b>Australien.</b>
        </li>
        <li>
          “Breaking the law, breaking the law" sjungs av <b>Judas Priest</b>.
        </li>
      </ol>
    </div>
  );
};

const MixMegapol = () => {
  return (
    <div>
      <ol>
        <li>
          Miss Li's förnamn är <b>Linda</b>.
        </li>
        <li>
          Darin har <b>inte vunnit Idol</b>, han blev tvåa 2004.
        </li>
        <li>
          "I can buy myself flowers" sjungs av <b>Miley Cyrus</b> (låten
          Flowers).
        </li>
      </ol>
      <ol>
        <li>
          Benjamin Ingrosso <b>har vunnit</b> Mello.
        </li>
        <li>
          Gry Forssell kommer från <b>Norrköping</b>.
        </li>
        <li>
          "Summer Of ‘69" sjungs av <b>Bryan Adams</b>.
        </li>
      </ol>
      <ol>
        <li>
          Svag är en låt av <b>Victor Leksell</b>.
        </li>
        <li>
          Kungahuset är <b>inte en byggnad</b>, det är en institution.
        </li>
        <li>
          "Michelangelo" sjungs av <b>Björn Skifs</b>.
        </li>
      </ol>
    </div>
  );
};

const NRJ = () => {
  return (
    <div>
      <ol>
        <li>
          Taylor Swifts fans kallas <b>Swifties</b>.
        </li>
        <li>
          Beyoncé var medlem i gruppen <b>Destiny's Child</b>.
        </li>
        <li>
          "Ecuador" sjungs av <b>Sash!</b>
        </li>
      </ol>
      <ol>
        <li>
          David Guetta kommer från <b>Frankrike</b>.
        </li>
        <li>
          Några av Pinks kändaste låtar är:{" "}
          <b>
            So What, Just Give Me a Reason, Raise Your Glass, Cover Me in
            Sunshine, TRUSTFALL, Try, Who Knew, Get the Party Started
          </b>
          .
        </li>
        <li>
          "Despacito" sjungs av <b>Luis Fonsi</b> (ft. Daddy Yankee).
        </li>
      </ol>
      <ol>
        <li>
          Ed Sheeran <b>föddes inte på 00-talet</b>, han föddes 1991.
        </li>
        <li>
          Zara Larsson <b>vann Talang</b> 2008.
        </li>
        <li>
          "I Gotta Feeling" sjungs av <b>Black Eyed Peas</b>.
        </li>
      </ol>
    </div>
  );
};

const RadioNostalgi = () => {
  return (
    <div>
      <ol>
        <li>
          Mauro Scocco väntar på <b>Sara</b> vid 7-Eleven.
        </li>
        <li>
          Nena sjunger om <b>99 luftballonger</b>.
        </li>
        <li>
          "Forever Young" sjungs av <b>Alphaville</b>.
        </li>
      </ol>
      <ol>
        <li>
          a-ha kommer från <b>Norge</b>.
        </li>
        <li>
          The Beatles sjunger om en <b>gul ubåt</b>.
        </li>
        <li>
          "I'm walking on sunshine" sjungs av <b>Katrina and the Waves</b>.
        </li>
      </ol>
      <ol>
        <li>
          Diana Ross var med i <b>The Supremes</b>.
        </li>
        <li>
          Live Aid anordnades <b>1985</b>.
        </li>
        <li>
          "We can dance if you want to" sjungs av <b>Men Without Hats</b> (låten
          The Safety Dance).
        </li>
      </ol>
    </div>
  );
};
