import slide1 from '../assets/slide1.jpg';
import slide2 from '../assets/slide2.jpg';
import slide3 from '../assets/slide3.jpg';


export const SliderData = [
    {
        image: slide1,
        paper: "DN",
        headline: "Livesänd quiz-app tar frågesport till en ny nivå: ”I framtiden kommer man skratta åt vanlig frågesport-tv”",
        url: "https://www.aftonbladet.se/nojesbladet/a/Wb09jj/hemligheterna-bakom-framgangen-med-primetime/salesposter?useFlexbox=true&shared=false&reason=denied"
    },
    {
        image: slide2,
        paper: "Breakit",
        headline: 'Primetime köps upp av Mag Interactive: "Passar perfekt in" ',
        url: "https://www.aftonbladet.se/nojesbladet/a/Wb09jj/hemligheterna-bakom-framgangen-med-primetime/salesposter?useFlexbox=true&shared=false&reason=denied"
    },
    {
        image: slide3,
        paper: "Aftonbladet",
        headline: 'Hemligheterna bakom framgången med ”Primetime"',
        url: "https://www.aftonbladet.se/nojesbladet/a/Wb09jj/hemligheterna-bakom-framgangen-med-primetime/salesposter?useFlexbox=true&shared=false&reason=denied"
    },
]
