import React from 'react'


const OneButtonModal = ({title, text, btntext, color, destination}) => {
    return (
        <div className="modal-container">
            <div className="modal-content">
                <h2 className="modal-title-text">
                    {title}
                </h2>
                <p className="modal-body-text">
                    {text}
                </p>
                <a 
                href={destination}
                target="_blank"
                rel="noreferrer"
                className="modal-btn"
                style={{background: color}}
                >
                        {btntext}
                </a>
            </div>
        </div>
    )
}



export default OneButtonModal;