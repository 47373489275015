import React from "react";
import { Helmet } from "react-helmet";
import HostCardList from "../../components/Modals/HostCardList.js";
import CollapsibleModal from "../../components/CollapsibleModal.js";
import TitleCard from "../../components/TitleCard.js";
import agnes from "../../assets/profiles/agnes3.jpg";
import felix from "../../assets/profiles/felix.jpg";
import erik from "../../assets/profiles/erik.jpg";
import fabian from "../../assets/profiles/fabian.jpg";
import martin from "../../assets/profiles/martin.jpg";
import josefine from "../../assets/profiles/josefine.jpg";
import daniel from "../../assets/profiles/daniel.jpg";
import emil from "../../assets/profiles/emil.jpg";
import ivar from "../../assets/profiles/ivar2.png";
import pstandard from "../../assets/profiles/pstandard.png";
import osama from "../../assets/profiles/osama.jpg";
import olivia from "../../assets/profiles/olivia.jpg";
import knut from "../../assets/profiles/knut.png";

function AboutView() {
  return (
    <div className="default-page-container emoji-image-2">
      <Helmet>
        <title>Om Primetime</title>
        <meta
          name="description"
          content="Läs om hur Primetime grundades och lär känna våra fantastiska programledare och medarbetare!"
        />
      </Helmet>

      <div className="advertisers-container">
        <TitleCard
          title="Om oss 🤓"
          body="Läs om hur Primetime grundades och lär känna våra fantastiska medarbetare och programledare."
        />

        <CollapsibleModal
          emoji="🧑‍🏫"
          title="Vår historia"
          body={<PrimetimeHistory />}
        />
      </div>

      <div className="faq-text-container-card">
        <div className="card-container">
          <HostCardList hostcards={WorkersData} />
        </div>
      </div>
    </div>
  );
}

const MartinText = () => {
  return (
    <div className="card-text">
      <h2>Hur var din första sändning?</h2>
      <p>
        Det var precis i början och tror vi hade lite drygt 1000 spelare men
        väldigt nervöst. Alltså orimligt nervöst. Benen skakade på ett sätt de
        inte gjort varken före eller efter. Jag hade inte gjort något liknande
        förut och hade gjort det mindre kloka i att säga till folk att de skulle
        spela så fallhöjden var liksom lite för hög.
      </p>
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>
        Spelar Smart 10 (otroligt sällskapsspel), sitter på restaurang med massa
        vänner (och överdriver precis lagom) eller är dålig på någon bollsport
        (vi gick till final i Reklamserien!).{" "}
      </p>
      <h2>Vill du rekommedera något?</h2>
      <p>Säg hej, säg tack och parkera din Voi ordentligt.</p>
    </div>
  );
};

const AgnesText = () => {
  return (
    <div className="card-text">
      <h2> Vad gör du helst när du inte jobbar?</h2>
      <p>Spelar Minecraft och tittar på ASMR.</p>
      <h2>Gör du något mer än att jobba på Primetime?</h2>
      <p>Jag pluggar just nu min master i interaktiv medieteknik på KTH.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>Jag vill rekommendera läsplatta! Och att se The Wire på HBO.</p>
    </div>
  );
};

const EmilText = () => {
  return (
    <div className="card-text">
      <h2>Gör du något mer än att jobba på Primetime?</h2>
      <p>Frilansar inom TV och dubbar lite tecknat.</p>
      <h2>Vilken är din favoritmat?</h2>
      <p>Pannkakor.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>Förutom att spela Primetime? Ett fast handslag.</p>
    </div>
  );
};

const JosefinText = () => {
  return (
    <div className="card-text">
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>
        Jag älskar The Real Housewives, om jag inte tittar på det är jag på stan
        med vännerna!
      </p>
      <h2>Bästa boken du läst?</h2>
      <p>Tuesdays with Morrie!!!</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>Livet blir så mycket bättre med färgglada strumpor!</p>
    </div>
  );
};

const OliviaText = () => {
  return (
    <div className="card-text">
      <h2>Gör du något mer än att jobba på Primetime?</h2>
      <p>
        Ja! Jag gör morgonshowen Rix Morronzoo på Rix FM varje vardag
        tillsammans med Roger Nordin och Laila Bagge. Tune in vettja!
      </p>
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>
        Då tittar jag på alldeles för många avsnitt av någon serie eller dricker
        Amaretto Sour med mina vänner.
      </p>
      <h2>Bästa boken du läst?</h2>
      <p>
        Egenmäktigt förfarande av Lena Andersson. Det borde vara lag på att alla
        måste läsa den minst en gång i livet!
      </p>
    </div>
  );
};

const ErikText = () => {
  return (
    <div className="card-text">
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>Kollar fotboll och går på konserter.</p>
      <h2>Bästa filmen du har sett?</h2>
      <p>500 Days of Summer, utan tvekan.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>
        Lucy Rose. En otrolig artist och människa som får alldeles för lite
        uppmärksamhet i musikbranschen. Kan varmt rekommendera hennes
        minidokumentär "Somethings Changing" på Youtube!
      </p>
    </div>
  );
};

const OsamaText = () => {
  return (
    <div className="card-text">
      <h2>Vilken är din favoritmat?</h2>
      <p>Sushi.</p>
      <h2>Bästa filmen du har sett?</h2>
      <p>12 angry men och Interstellar.</p>
      <h2>Bästa boken du läst?</h2>
      <p>1984, Crime and Punishment.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>
        Använd betalt VPN för din cybersäkerhet och kolla Friends om man har
        inte gjort än.
      </p>
    </div>
  );
};

const DanielText = () => {
  return (
    <div className="card-text">
      <h2>Hur var din första sändning?</h2>
      <p>
        Alltså jag har ju faktiskt varit programledare hela tre gånger! första
        körde jag med Rickard Olsson, Mr Frågesport. Det var kul!{" "}
      </p>
      <h2>Gör du något mer än att jobba på Primetime?</h2>
      <p>Nej:-) </p>
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>Leker med mina barn.</p>
    </div>
  );
};

const IvarText = () => {
  return (
    <div className="card-text">
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>Spelar squash, shuffleboard eller datorspel.</p>
      <h2>Vilken är din favoritmat?</h2>
      <p>Dumplings och kantarelltoast.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>En weekend i min favoritstad Helsingfors! Och filmen Tove.</p>
    </div>
  );
};

const FelixText = () => {
  return (
    <div className="card-text">
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>Kollar på en riktigt spännande thriller.</p>
      <h2>Gör du något mer än att jobba på Primetime?</h2>
      <p>Skådespelar i lite projekt här och där.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>Följ världen: Kolla på Squidgame!</p>
    </div>
  );
};

const FabianText = () => {
  return (
    <div className="card-text">
      <h2>Vad gör du helst när du inte jobbar?</h2>
      <p>Spelar tv-spel och läser.</p>
      <h2>Vilken är din favoritmat?</h2>
      <p>Renskav, definitivt.</p>
      <h2>Vill du rekommendera något eller någon?</h2>
      <p>Prova Primetime, lär dig något kul språk.</p>
    </div>
  );
};

const EmptyText = () => {
  return (
    <div className="card-text">
      <h2>Under konstruktion!</h2>
    </div>
  );
};

const PrimetimeHistory = () => {
  return (
    <p className="expanded-modal-body">
      Primetime grundades i Stockholm 2018 av Tomas Jämtbäck, Johan Ribb, Petter
      Borggård, Martin Palm, Daniel Weilar och Fredrik Kilian.
      <br />
      <br />
      Den 16 mars 2018 sändes det allra första quizet i appen och 43 spelare
      deltog. 11 månader senare hade appen över en miljon nedladdningar och i
      februari 2019 svarade 142.325 spelare på den första frågan - ett rekord
      som står sig alltjämt. Totalt har över 1,5 miljoner svenskar någon gång
      spelat Primetime och hösten 2023 är det nästan 100.000 unika spelare som
      varje månad spelar Primetime. Och vi fortsätter att sända varje dag, sedan
      den 16 mars 2018 har vi inte missat en enda dag.
      <br />
      <br />
      Primetime vann juryns specialpris i Kristallen 2019 för sitt nyskapande
      koncept och innehåll. Primetime har periodvis även varit live i Norge,
      Finland, Danmark, Polen och Tyskland (under namnet QuizDuel Live). 2020
      köptes Primetime upp av MAG Interactive AB, ett svenskt börsnoterat
      företag som till exempel står bakom mobilspelen Quizkampen och Razzle.
      <br />
      <br />
      Spelet finansieras genom annonsörer och Primetime har samarbetat med stora
      internationella företag, exempelvis Spotify, Coca-Cola, Gillette och
      Dagens Nyheter. Spelarna kan alltid välja att skänka sina vinstpengar till
      välgörenhet – hittills har det skänkts nästan 1,5 miljon kronor.
      <br />
      <br />
      Vi tycker att frågesport är det roligaste som finns och jobbar varje dag
      för att Primetime ska bli så bra som möjligt.{" "}
      <b>Tack för att du spelar och lycka till ikväll!</b>
    </p>
  );
};

const WorkersData = [
  {
    img: agnes,
    name: "Agnes Shutrick ",
    title: "Programledare",
    desc: <AgnesText />,
    id: 1,
    mail: "agnes.shutrick@primetimequiz.com",
  },
  {
    img: daniel,
    name: "Daniel Weilar",
    title: "Grundare & Affärsutveckling",
    desc: <DanielText />,
    id: 2,
    mail: "daniel.weilar@primetimequiz.com",
  },
  {
    img: pstandard,
    name: "Douglas Cooper",
    title: "Sociala medier & Redaktör",
    desc: <EmptyText />,
    id: 3,
    mail: "douglas.cooper@primetimequiz.com",
  },
  {
    img: emil,
    name: "Emil Apell",
    title: "Programledare",
    desc: <EmilText />,
    id: 4,
    mail: "emilapell@primetimequiz.com",
  },
  {
    img: erik,
    name: "Erik Bolldén",
    title: "Grafisk designer & Programledare",
    desc: <ErikText />,
    id: 5,
    mail: "erik.bollden@primetimequiz.com",
  },
  {
    img: fabian,
    name: "Fabian Amidani",
    title: "Grafisk designer",
    desc: <FabianText />,
    id: 9,
    mail: "fabian.amidani@primetimequiz.com",
  },
  {
    img: felix,
    name: "Félix Germaneau",
    title: "Programledare",
    desc: <FelixText />,
    id: 10,
    mail: "felix.germaneau@primetimequiz.com",
  },
  {
    img: ivar,
    name: "Ivar Wilenius",
    title: "Redaktör",
    desc: <IvarText />,
    id: 11,
    mail: "ivar.wilenius@primetimequiz.com",
  },
  {
    img: pstandard,
    name: "Jan Tauer",
    title: "Programledare",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: josefine,
    name: "Josefin Lindgren ",
    title: "Account Manager",
    desc: <JosefinText />,
    id: 13,
    mail: "josefin.lindgren@primetimequiz.com",
  },
  {
    img: knut,
    name: "Knut Paulson",
    title: "Sändningstekniker",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: pstandard,
    name: "Lovisa Rogers",
    title: "Sändningstekniker",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: martin,
    name: "Martin Palm",
    title: "Grundare, VD & Programledare",
    desc: <MartinText />,
    id: 15,
    mail: "martin.palm@primetimequiz.com",
  },
  {
    img: pstandard,
    name: "Robin Berglund",
    title: "Programledare",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: pstandard,
    name: "Samuel Eriksson",
    title: "Sändningstekniker",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: pstandard,
    name: "Stina Neubeck",
    title: "Sändningstekniker",
    desc: <EmptyText />,
    id: 19,
    mail: " ",
  },
  {
    img: olivia,
    name: "Olivia J Berntsson",
    title: "Programledare",
    desc: <OliviaText />,
    id: 18,
    mail: "oliviajberntsson@gmail.com",
  },
  {
    img: osama,
    name: "Osama Alajam",
    title: "Utvecklare",
    desc: <OsamaText />,
    id: 19,
    mail: "osama.alajam@primetimequiz.com",
  },
];

export default AboutView;
