import React from 'react'; 

const ScrollToContactForm = ({children, props}) => {
    const scrollToForm = () => {
        window.scrollTo(0, window.scrollY + document.querySelector('#contact-form-id').getBoundingClientRect().top - 100) // Y
    }
    
    return (
        <button 
        className={props.inline ? "inline-btn-ads" : "button-ads"}
        onClick={scrollToForm}>{children}</button>
    )
}


export default ScrollToContactForm

