export const PrivacyMarkup = () => {
  return (
    <div>
      <p className="expanded-modal-body">
        Sventertainment AB, the publisher of Primetime, welcomes and thanks you
        for your interest in Primetime (“Primetime”, “We”, or “Us”), and our
        website (the “Site”), and all related mobile applications, downloaded
        software and other services provided by us and on which a link to this
        Privacy Policy is displayed (collectively, together with the Site, our
        “Service”).We respect your privacy and are committed to protecting the
        personal information that you may provide while using our websites and
        services. This Privacy Policy describes the information of yours we will
        be collecting, how the information will be used, and the measures we
        take in order to protect such information. We will not use or share your
        information with anyone except as described in this Privacy Policy.
      </p>
      <p className="expanded-modal-body">
        By using our Services, you agree that you have understood the content of
        this Privacy Policy. If you do not agree with this Privacy Policy,
        please do not use our Services.
      </p>
      <h2>1. The information we collect on the Service</h2>
      <p className="expanded-modal-body">
        We collect different types of information about you from or through our
        Service.
      </p>
      <ul>
        <li>
          <b>Registration information</b>
          <br />
          When you use the Service, you may provide, and we may collect personal
          data about you, such as your phone number.
        </li>
        <br />
        <li>
          <b>Contact list information</b>
          <br />
          When you use the Service, you may challenge a friend from your contact
          list. This requires us to temporary access, collect, store and upload
          your contacts to our server so that we match your friends’ phone
          numbers from your contact list to players’ phone numbers in the
          Service. This is done so that the phone numbers from your contact list
          are matched with players that are using the Service and, where
          applicable, provide the friends from your contact list that are using
          the Service with a challenge request. All collected personal data from
          your contact list is thereafter removed. We’re not sharing any contact
          list information, such as your phone number with any third party.
        </li>
        <br />
        <li>
          <b>Image upload information</b>
          <br />
          When you use the Service, you may upload any image to your profile
          picture in our service. It’s not required, uploaded image can be any
          image and it’s not necessary to be a real person image. We’re not
          sharing any image information.
        </li>
        <br />
        <li>
          <b>Usage information</b>
          <br />
          When you use the Service, we may automatically collect information
          about your device and how the Service is used. This information may be
          personal data such as the IP of your device or other device addresses,
          or non-personal data such as operating system, browser information,
          and click path.
        </li>
        <br />
      </ul>

      <h2>2. How we use the information we collect</h2>
      <ul>
        <li>
          To operate, maintain, enhance and provide all features of the Service.
        </li>
        <li>
          To administrate your account, and provide integration in the chat
          room.
        </li>
        <li>
          To make sure the Service will function correctly and improve it, to
          understand and analyse the usage and downloads of the Service.
        </li>
        <li>To enable and promote the Service.</li>
        <li>To enable the payment in a case of a win in the Service.</li>
        <li>
          To personalize the Service, such as remembering your information so
          you will not have to login or re-enter the information every visit.
        </li>
        <li>
          To provide customizable advertisements, offers, and marketing
          messages, subject to your consent.
        </li>
        <li>
          To monitor and analyse your use of the Service and the way you
          interact with the targeted ads, in order to make calculated business
          decisions.
        </li>
        <li>As otherwise stated in this Privacy Policy.</li>
      </ul>

      <h2>3. How we share your information</h2>
      <ul>
        <li>
          <b>We will not rent nor sell your personal information to others</b>
          <br />
          We may share your information with third-party tracking company which
          processes data on our behalf and it is subject to security and
          confidentiality obligations according to this Privacy Policy, GDPR,
          and applicable law. The information will be shared with these
          third-parties for these purposes: promotional, advertisement,
          marketing, bugs finding, and improvement of the Service.
        </li>
        <br />
        <li>
          <b>
            In the event that we are required to share your information we will
            do so if it is required by law, if it is subject to subpoena or
            other legal proceeding or if we have a reasonable belief that such
            action is necessary to:
          </b>
          <br />
          a) comply with the law and the reasonable requests of the law
          enforcement;
          <br />
          b) to enforce our Terms of Service or to protect the security or
          integrity of our Service; and or
          <br />
          c) to exercise or protect the rights, property or personal safety of
          us, our Service, users or others.
        </li>
        <br />
        <li>
          <b>Primetime hosts a chat room</b>
          <br />
          Please remember that any information that you choose to share in these
          areas becomes public information, will be available to all and any of
          the users who have access to the Service. Therefore, you should
          exercise caution when deciding to disclose your personal information.
        </li>
        <br />
        <li>
          <b>
            In a case that a third-party processes data on its own behalf, its
            processing is subject to its own Privacy Policy and applicable law
          </b>
        </li>
      </ul>
      <h2>4. For how long we will store your data</h2>
      <p className="expanded-modal-body">
        We only retain your personal information collected according to this
        Privacy Policy as long as:
      </p>
      <ul>
        <li>you use our Service,</li>
        <li>
          as necessary to comply with our legal obligations, resolve disputes,
          and enforce our agreements,
        </li>
        <li>we need to process any payments remaining,</li>
        <li>we need to, in order to prevent a crime.</li>
      </ul>

      <h2>5. Transfer, storage and processing in another country</h2>
      <p className="expanded-modal-body">
        Your information may be stored and processed in countries which are not
        located in Switzerland or the EEA. The country it is transferred to
        might not have the same laws as your country. However, in the event that
        your information will be transfer to this other country, we will make
        sure that it is protected in accordance with the GDPR requirements and
        set up contracts with this party to ensure that.
      </p>

      <h2>6. How we protect your information</h2>
      <p className="expanded-modal-body">
        We are concerned with protecting your information. We follow generally
        accepted industry standards to protect the information submitted to us.
        We do our best to maintain administrative, technical, and physical
        safeguard to help protect your personal information from loss,
        destruction, misuse, unauthorized access, disclosure, alteration and any
        other unlawful form of processing of your personal information in our
        possession. We use software programs to monitor network traffic to
        identify unauthorized attempts to upload or change information, or
        otherwise cause damage. However, no method of transmission over the
        Internet or method of electronic storage is 100% secure. Therefore, we
        cannot guarantee the information’s absolute security.
      </p>

      <h2>7. Children’s privacy</h2>
      <p className="expanded-modal-body">
        We do not allow children under the age of 18 to register with our
        Service, therefore we do not knowingly collect personal information from
        children. If you believe that we have any information from or about a
        child under 18, please contact us at support@primetimequiz.com.
      </p>

      <h2>8. Your rights regarding your information</h2>
      <p className="expanded-modal-body">
        You have a range of rights in connection with our processing of your
        personal information. In this regard, you are always entitled to receive
        information on data that we have on you, without charge. You also have
        the right to request it to be deleted, restrict the processing of it and
        to get any inaccurate or incomplete personal information blocked or
        corrected. Where we rely on consent to process your data, you can remove
        this consent at any time. Additionally, you also have the right to data
        portability in a case it will become relevant. Furthermore, you can
        object to our processing of your personal information. If you have a
        complaint regarding the processing of your data, you have right to lodge
        a complaint with the local supervisory authority. The contact details of
        the Swedish supervisory data authority can be found in{" "}
        <a href="https://www.datainspektionen.se/">www.datainspektionen.se</a>.
        <br />
        If you are not happy with the way we handle your information or you have
        any questions about this Privacy Policy or your rights under the GDPR,
        please contact support@primetimequiz.com.
      </p>

      <h2>9. Changes to the privacy policy</h2>
      <p className="expanded-modal-body">
        We may make changes to this Privacy Policy. If we will make changes we
        will let you know by displaying a notice in our Service or via email,
        prior to the change becoming effective. If the changes are minor, we
        will just make them here. Please make sure you read the notices
        carefully.
      </p>

      <h2>10. How to contact us</h2>
      <p className="expanded-modal-body">
        If you have any questions about this Privacy Policy, please contact us
        at support@primetimesweden.se.
      </p>
    </div>
  );
};
