import React from "react";
import { Link, useLocation } from "react-router-dom";

function Header({
  currentPage,
  setCurrentPage,
  className,
  sideBarActive,
  setSideBarActive,
}) {
  const headerItems = [
    "Hem",
    "Om oss",
    "Radiofrågor",
    "Support",
    "Annonsering",
    "Press",
    "Shop",
  ];
  let currentPath = useLocation();

  React.useEffect(() => {
    matchItemToPath();
  }, [currentPath]);

  const matchItemToPath = () => {
    if (currentPath.pathname === "/") {
      setCurrentPage("Hem");
    } else if (currentPath.pathname === "/om") {
      setCurrentPage("Om oss");
    } else if (currentPath.pathname === "/radiofragor") {
      setCurrentPage("Radiofrågor");
    } else if (currentPath.pathname === "/annonsering") {
      setCurrentPage("Annonsering");
    } else if (currentPath.pathname === "/support") {
      setCurrentPage("Support");
    } else if (currentPath.pathname === "/press") {
      setCurrentPage("Press");
    }
  };

  return (
    <nav>
      <div className={className}>
        {sideBarActive ? (
          <div className="close-button" onClick={() => setSideBarActive(false)}>
            <div className="close-line-1"></div>
            <div className="close-line-2"></div>
          </div>
        ) : (
          <div
            className="hamburger-menu"
            onClick={() => setSideBarActive(true)}
          >
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
          </div>
        )}
        <div className={sideBarActive ? "sidebar-items" : "header-items"}>
          {headerItems.map((headerItem) => (
            <div
              className={sideBarActive ? "sidebar-item" : "header-item"}
              key={headerItems.indexOf(headerItem)}
              onClick={() => setSideBarActive(false)}
            >
              {headerItem === "Shop" ? (
                <a
                  className="unselected-link"
                  href="https://www.primetimeshop.se/"
                  target="_blank"
                >
                  Shop
                </a>
              ) : (
                <Link
                  to={
                    headerItem === "Hem"
                      ? "/"
                      : headerItem === "Om oss"
                      ? "/om"
                      : `/${headerItem
                          .toLowerCase()
                          .replace(/ /g, "")
                          .replace(/ä|å|ö/g, (letter) => {
                            return letter === "å"
                              ? "a"
                              : letter === "ä"
                              ? "a"
                              : letter === "ö"
                              ? "o"
                              : "";
                          })}`
                  }
                  className={
                    currentPage === headerItem
                      ? "selected-link"
                      : "unselected-link"
                  }
                >
                  {headerItem}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Header;
