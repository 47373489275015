import React from "react";
import { Helmet } from "react-helmet";
import santamarialogo from "../../assets/advertisers/santamaria.png";
import comviqlogo from "../../assets/advertisers/comviq.png";
import wwflogo from "../../assets/advertisers/wwf.png";
import svtlogo from "../../assets/advertisers/svt.png";
import gplogo from "../../assets/advertisers/gp.png";
import CollapsibleModal from "../../components/CollapsibleModal.js";
import TitleCard from "../../components/TitleCard.js";
import ContactForm from "../../components/ContactForm.js";
import ScrollToContactForm from "../../components/ScrollToContactForm.js";

function AdvertisersView() {
  return (
    <div className="default-page-container emoji-image-1">
      <Helmet>
        <title>Primetime for advertisers</title>
        <meta
          name="description"
          content="Primetime är en effektiv annonsyta där ditt företag kan synas i en sponsrad sändning. Vi erbjuder även företagsquiz till er kick-off!"
        />
      </Helmet>

      <div className="advertisers-container">
        <TitleCard
          title="Annonsering 😍"
          body="Nå ut till tiotusentals spelare eller bjud kollegorna på en skräddarsydd show!"
        />

        <CollapsibleModal
          emoji="🧑‍💻"
          title="Annonsering"
          body={<SponsoredQuiz />}
        />
        <CollapsibleModal
          emoji="🕺"
          title="Företagsquiz"
          body={<CompanyQuiz />}
        />

        <ScrollToContactForm props={{ inline: false }}>
          Kontakta oss direkt
        </ScrollToContactForm>

        <div className="partners-description-container">
          <div className="partners-description-content">
            <div className="partners-description-text">
              <h2 className="partners-description-title">
                Tidigare samarbeten!
              </h2>
            </div>
          </div>
        </div>

        {AdvertiserData.map((advertiser) => (
          <CollapsibleModal
            img={advertiser.logo}
            imgWidth="50px"
            title={advertiser.name}
            subtitle="Primetime x"
            body={advertiser.description()}
            key={AdvertiserData.indexOf(advertiser)}
          />
        ))}

        <ContactForm
          title="Vill du samarbeta med oss?"
          description="Tveka inte på att kontakta oss via formuläret nedan. Du kan också höra av dig till vår VD på martin.palm@primetimequiz.com."
          ticketType="business"
          backupEmail="samarbete@primetimequiz.com"
        />
      </div>
    </div>
  );
}

export default AdvertisersView;

const CompanyQuiz = () => {
  return (
    <div>
      <p className="expanded-modal-body">
        Har du spelat Primetime och vill visa dina kollegor hur kul det är?
        Samlas ni tillsammans på kontoret varje fredag 15:00 för att spela
        Primetimes AW-quiz? Eller är ni ett kunskapstörstande gäng som älskar
        att tävla mot varandra? Primetime anordnar exklusiva företagsquiz där du
        och ditt företag helt bestämmer innehållet. Primetimes företagsquiz är
        en perfekt aktivitet på företagsfesten, kickoffen eller till
        fredagsfikat. Vi står för studio, teknik, programledare, frågor och
        produktion. Sändningen håller samma höga kvalitet som en ordinarie
        spelomgång, men bara de som ni väljer att bjuda in får spela.
        <br />
        <br />
        <b style={{ fontSize: "large" }}>Programledare</b>
        <br />
        <br />
        Vi har programledare som mer än gärna håller en rolig sändning för dig
        och dina kollegor. Om ni istället vill ha en eller flera programledare
        från ert håll så funkar det lika bra. Det går också att programleda
        tillsammans med Primetimes programledare. Vår studio ligger i centrala
        Stockholm.
        <br />
        <br />
        <b style={{ fontSize: "large" }}>Frågor</b>
        <br />
        <br />
        En sändning består vanligtvis av 11 stycken frågor, men det är också
        möjligt med en så kallad “one winner takes it all” där frågor ställs
        till det att en spelare står kvar. Ni har möjlighet att bestämma vilka
        frågor som ska ställas, men kan också lämna det ansvaret till oss.
        <br />
        <br />
        <b style={{ fontSize: "large" }}>Grafiskt material</b>
        <br />
        <br />
        Vi anpassar sändningen efter er grafiska profil och vision.
      </p>
    </div>
  );
};

const SponsoredQuiz = () => {
  return (
    <div>
      <p className="expanded-modal-body">
        Vill du nå hundratusentals spelare varje vecka? Vill du prata med dem i
        åtta minuter? Vill du interagera och engagera? Utbilda? Driva
        försäljning? Då är Primetime den perfekta mediekanalen.
        <br />
        <br />
        <b style={{ fontSize: "large" }}>
          Primetime skapar engagemang och interaktivitet
        </b>
        <br />
        <br />
        Vi kan skräddarsy unika upplägg för ditt varumärke. Vi kan också erbjuda
        standardlösningar där vi visar prerolls och driver försäljning genom
        utlänkar. Varje spelare spenderar i snitt 8 minuter per sändning och vi
        anpassar hela sändningen efter er varumärkesprofil med grafik, ljud och
        video. Vi har endast en annonsör per show och förstärker ert budskap med
        professionella programledare. Givetvis kan vi också skapa upplägg som
        bygger på enskilda sponsrade frågor, spännade prispotter eller specifika
        teman.
        <br />
        <br />
        <b style={{ fontSize: "large" }}>Sociala medier</b>
        <br />
        <br />
        Primetime har 75 000 användare på Instagram. Primetime sätter kunskap
        och utbildning i fokus vilket skapar trovärdighet.
        <br />
        <br />
        Kontakta oss så berättar vi mer! Inget företag är för stort eller för
        litet.
      </p>
    </div>
  );
};

const AdvertiserData = [
  {
    logo: santamarialogo,
    name: "Santa Maria",
    color: "pink",
    description: () => {
      return (
        <div className="expanded-modal-body">
          <p>
            I sändningen tillsammans med Santa Maria gästades Primetime av
            ambassadörerna <b>Margaux Dietz och Daniel Norberg</b>. Vissa frågor
            gick att koppla till Santa Maria och Santa Marias produkter vilket
            gjorde att varumärket vävdes in i innehållet på ett naturlig sätt.
            <br />
            <br />
            Hela sändningen brandades i linje med Santa Marias visuella
            identitet. Sändningen sågs av 27 000 personer.
          </p>
          <div className="watch-broadcast-btn-container">
            <a
              className="watch-broadcast-btn"
              href="https://vimeo.com/manage/videos/512903183/71071de6d3"
              target="_blank"
              rel="noreferrer"
            >
              Se sändningen! 📺
            </a>
          </div>
        </div>
      );
    },
  },
  {
    logo: wwflogo,
    name: "WWF",
    color: "pink",
    description: () => {
      return (
        <div className="expanded-modal-body">
          <p>
            När WWF var med som sponsor valde vi att göra ett quiz med tema
            vilda djur. Temat gjorde det naturligt att prata om WWF och WWFs
            arbete. Varje månad har Primetime en välgörenhetspartner som
            spelarna kan skänka sina vinstpengar till, och månaden för quizzet
            var WWF välgörenhetspartner. Programledaren uppmanade därför
            spelarna att skänka sina vinster till WWF.
            <br />
            <br />
            Sändningen brandades i linje med WWFs visuella identitet. WWF bidrog
            även med djurkostymer till programledare och tekniker, samt två
            videofrågor. Sändningen sågs av 25 000 personer.
          </p>
          <div className="watch-broadcast-btn-container">
            <a
              className="watch-broadcast-btn"
              href="https://vimeo.com/583331454/1fcedaacc8"
              target="_blank"
              rel="noreferrer"
            >
              Se sändningen! 📺
            </a>
          </div>
        </div>
      );
    },
  },
  {
    logo: svtlogo,
    name: "SVT",
    color: "pink",
    description: () => {
      return (
        <div className="expanded-modal-body">
          <p>
            Under sommaren 2021 samarbetade Primetime och SVT Nyheter i ett
            nyhetsquiz. Spelomgången utformades som en nyhetssändning med frågor
            om veckoaktuella händelser och nyheter.
            <br />
            <br />
            Hela sändningen hade SVT som avsändare. Sändningen sågs av 17 000
            personer.
          </p>
          <div className="watch-broadcast-btn-container">
            <a
              className="watch-broadcast-btn"
              href="https://vimeo.com/583358905/a0ea27bad1"
              target="_blank"
              rel="noreferrer"
            >
              Se sändningen! 📺
            </a>
          </div>
        </div>
      );
    },
  },
  {
    logo: comviqlogo,
    name: "Comviq",
    color: "pink",
    description: () => {
      return (
        <div className="expanded-modal-body">
          <p>
            Comviq deltog som annonsör i en nervkittlande sändning våren 2021. I
            potten låg det <b>10 000 kr och en iPhone 12</b>. Kvällens 12 frågor
            gav alla varsin ledtråd till det telefonnummer som tillhör
            telefonen. Den spelare som först ringde rätt telefonnummer vann
            telefonen. I slutet av sändningen ringde telefonen som låg i studion
            och den lyckliga vinnaren grattades av programledaren live.
            <br />
            <br />
            Hela sändningen brandades i linje med Comviqs visuella identitet.
            Comviq bidrog med videofrågor och hälsningar som gästades av Comviqs
            profiler Karim och Nadja. Sändningen sågs av 23 000 personer.
          </p>
          <div className="watch-broadcast-btn-container">
            <a
              className="watch-broadcast-btn"
              href="https://vimeo.com/583339325/c4243d9488"
              target="_blank"
              rel="noreferrer"
            >
              Se sändningen! 📺
            </a>
          </div>
        </div>
      );
    },
  },
  {
    logo: gplogo,
    name: "GP",
    color: "blue",
    description: () => {
      return (
        <div className="expanded-modal-body">
          <p>
            Under sommaren 2020 kunde Göteborgs-Postens prenumeranter spela ett
            nyhetsfokuserat extra-quiz varje fredag klockan 16:00. Sändningarna
            var{" "}
            <b>bara tillgängliga för de som prenumererar på Göteborgs-Posten</b>
            . Varje vecka samlades i snitt 500 prenumeranter för att quizza om
            veckan som gått. Innehållet till frågorna hämtades från nyheter som
            Göteborgs-Posten publicerat. Resultatet blev roliga spelomgångar med
            mycket interaktion mellan programledare och chatt.
            <br />
            <br />
            Sändningen brandades i linje med Göteborgs-Postens visuella
            identitet och konceptet var starkt kopplat till Göteborg.
          </p>
          <div className="watch-broadcast-btn-container">
            <a
              className="watch-broadcast-btn"
              href="https://vimeo.com/583371524/73ac6c71c6"
              target="_blank"
              rel="noreferrer"
            >
              Se sändningen! 📺
            </a>
          </div>
        </div>
      );
    },
  },
  // {
  //     "logo": ingramlogo,
  //     "name": "Ingram",
  //     "color": "blue",
  //     "description": () => {
  //         return (
  //             <div className="advertiser-description-container">
  //                 <p>
  //                 Ingram Micro har genomfört flera stängda sändningar tillsammans med sina underleverantörer. De som har bjudits in har varit kunder och kollegor från runt om i Sverige. Primetimes vanliga frågor har kompletterats med frågor kopplade till Ingrams produkter.
  //                 </p>
  //                 <div className="watch-broadcast-btn-container">
  //                     <a
  //                     className="watch-broadcast-btn"
  //                     href="https://vimeo.com/583365605/8ad1262979"
  //                     target="_blank"
  //                     rel="noreferrer">
  //                         Se sändningen! 📺
  //                     </a>
  //                 </div>

  //             </div>
  //         )
  //     }
  // },
];
